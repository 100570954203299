<template>
  <div class="home">
    <div class="loading" v-if="loading">
      <van-loading size="24px">加载中...</van-loading>
    </div>
    <div class="bg-box">
      <img src="../assets/bg.jpg" alt="">
    </div>
    <div class="body">
      <div class="content-box">
        <p style="padding: .3rem 0;margin-left: .6rem;color: #8da9d2; white-space: nowrap;font-size: 0.8rem" v-if="obj.steelNo">
          请与二维码旁边的生产日期进行核对，确认是否一致
        </p>
        <span style="padding: .2rem 0;margin-left: .6rem;color: #ce3841;" v-if="obj.steelNo">本钢卷已被查询 <span
            style="font-size: 1.2rem;">{{
              obj.times }}</span> 次
        </span>
        <p style="padding: .2rem 0;margin-left: .6rem;" v-else>未找到钢卷信息</p>
      </div>
      <div class="content-box">
        <div class="content-header">
          <div class="line" />
          <h4>钢卷信息</h4>
        </div>
        <div class="content-body">
          <div class="content-list-item">
            <p class="label">钢卷号</p>
            <p class="value">{{ obj.steelNo }}</p>
          </div>
<!--          <div class="content-list-item">-->
<!--            <p class="label">钢种</p>-->
<!--            <p class="value">{{ obj.steelGrade }}</p>-->
<!--          </div>-->
          <div class="content-list-item">
            <p class="label">生产时间</p>
            <p class="value">{{ obj.scanTime }}</p>
          </div>
<!--          <div class="content-list-item">-->
<!--            <p class="label">带钢厚度</p>-->
<!--            <p class="value">{{ obj.thick }}</p>-->
<!--          </div>-->
<!--          <div class="content-list-item">-->
<!--            <p class="label">带钢宽度</p>-->
<!--            <p class="value">{{ obj.width }}</p>-->
<!--          </div>-->
<!--          <div class="content-list-item">-->
<!--            <p class="label">锌层上表面厚度</p>-->
<!--            <p class="value">{{ obj.xinThick }}</p>-->
<!--          </div>-->
<!--          <div class="content-list-item">-->
<!--            <p class="label">锌层下表面厚度</p>-->
<!--            <p class="value">{{ obj.xinThick1 }}</p>-->
<!--          </div>-->
        </div>
      </div>

<!--      <div class="content-box">-->
<!--        <div class="content-body">-->
<!--          <div class="content-list-item">-->
<!--            <p class="label">扫码IP</p>-->
<!--            <p class="value">{{ obj.ipAddress }}</p>-->
<!--          </div>-->
<!--          <div class="content-list-item">-->
<!--            <p class="label">地理位置</p>-->
<!--            <p class="value">{{ obj.address }}</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->





    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'HomeView',
  data() {
    return {
      loading: true,
      obj: {},
      ipAddress: ""
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      await this.inSync()
      let str = location.href.split("str=")[1]
      axios({
        method: 'post',
        url: '/api/blade-service/scancodelog/addScanLog',
        data: {
          str: str,
          ipAddress: this.ipAddress
        }
      }).then(res => {
        this.loading = false
        this.obj = res.data.data.data
      }).catch(err => {
        this.obj = {}
        this.loading = false
      })
    },


    async inSync() {
      return await fetch('http://ip-api.com/json/').then(res => res.json()).then(res => {
        let ipRegex = /^(([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])$/;
        if (ipRegex.test(res.query)) {
          this.ipAddress = res.query
        } else {
          this.ipAddress = ""
        }
      })
    }

  }
}
</script>

<style lang="scss">
.loading {
  width: 100%;
  position: absolute;
  height: 100vh;
  z-index: 2000;
  background-color: hsla(0, 0%, 100%, .9);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity .3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home {
  height: 100vh;
  background: #efefef;

  .bg-box {
    margin-bottom: 1vh;

    img {
      width: 100%;
      height: 20vh;
    }
  }

  .body {
    width: 96%;
    margin: 0 auto;

    .content-box {
      width: 100%;
      padding: 2vw;
      box-sizing: border-box;
      background: white;
      margin-bottom: 1vh;
      border-radius: 6px;

      .content-header {
        height: 5vh;
        background: #eff0f4;
        display: flex;
        align-items: center;
        border-radius: 0 5vw 5vw 0;
        margin-bottom: 1vh;

        .line {
          width: 1.6vw;
          height: 3vh;
          background: #8da9d2;
          margin: 0 3vw;
          border-radius: 1vw;
        }

        h4 {
          font-weight: bold;
          color: #8da9d2;
          font-size: 1.2rem;
        }
      }

      .content-body {
        margin-top: 2vh;

        .content-list-item {
          height: 4vh;
          display: flex;
          align-items: center;
          text-align: left;
          font-size: .8rem;
          border-bottom: 1px #f3f3f3 solid;

          .label {
            width: 30%;
            font-weight: bold;
            padding-left: 4vw;
          }

          .value {
            width: 60%;
            color: #8da9d2;
          }
        }
      }
    }
  }
}
</style>
